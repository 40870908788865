<template>
  <div class="">
    <TopBarTitleComponent showBackIcon title="Historial Suscripciones" />

    <b-container fluid class="pb-4 px-lg-4">
      <template v-if="apiResponse.success">
        <template>
          <section class="input-filters">
            <InputSearchComponent @onChangeSearch="onChangeSearch" />
            <SelectOrderByComponent @onSelectOrderBy="onSelectOrderBy" />
          </section>

          <section class="table-items table-items-subscription-history">
            <b-row class="table-items-header line-bottom">
              <b-col xs="12" sm="12" md="3">
                <h6 class="h6">Detalles</h6>
              </b-col>
              <b-col xs="12" sm="12" md="2">
                <h6 class="h6">Estado</h6>
              </b-col>
              <b-col xs="12" sm="12" md="2">
                <h6 class="h6">Fecha inicio</h6>
              </b-col>
              <b-col xs="12" sm="12" md="2">
                <h6 class="h6">Fecha finalización</h6>
              </b-col>
              <b-col xs="12" sm="12" md="1">
                <h6 class="h6">Valor</h6>
              </b-col>
              <b-col xs="12" sm="12" md="2">
                <h6 class="h6"></h6>
              </b-col>
            </b-row>

            <paginate
              class="wrapper-pagination"
              name="resultQuerySubscriptions"
              :list="resultQuerySubscriptions"
              :per="perPaginate"
              :key="searchQuery"
            >
              <div
                class="card table-items-body"
                v-for="(subscription, index) in paginated(
                  'resultQuerySubscriptions'
                )"
                :key="index"
              >
                <div class="card-body table-items-content">
                  <b-row class="">
                    <b-col xs="12" sm="12" md="3" class="table-col-item">
                      <p class="item-col-code font-semi-bold">
                        {{ getDesctiptionPlan(subscription.description) }}
                      </p>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" class="table-col-item">
                      <p class="item-col-detail font-bold">
                        <StatusSubscription :subscription="subscription" />
                      </p>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" class="table-col-item">
                      <p class="font-semi-bold item-col-status">
                        {{ subscription.starts_at }}
                      </p>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" class="table-col-item">
                      <p class="font-semi-bold item-col-status">
                        {{ subscription.ends_at }}
                      </p>
                    </b-col>
                    <b-col xs="12" sm="12" md="1" class="table-col-item">
                      <p class="font-semi-bold">${{ subscription.total }}</p>
                    </b-col>
                    <b-col
                      xs="12"
                      sm="6"
                      md="2"
                      class="table-col-item justify-content-center col-btn-cancel-subscription"
                    >
                      <MoreOptions
                        :options="options"
                        @optionSelected="
                          option => optionSelected(option, subscription)
                        "
                      />
                    </b-col>
                  </b-row>
                </div>
              </div>
            </paginate>
            <paginate-links
              v-show="resultQuerySubscriptions.length > perPaginate"
              for="resultQuerySubscriptions"
              :classes="{ ul: 'pagination', li: 'page-item', a: 'page-link' }"
              :simple="{
                next: 'Siguiente »',
                prev: '« Regresar'
              }"
            ></paginate-links>
          </section>
        </template>
      </template>
      <LoadingSkeletonTable v-else />
    </b-container>
  </div>
</template>

<script>
import TopBarTitleComponent from "../../components/TopBarTitle";
import InputSearchComponent from "../../components/InputSearch";
import SelectOrderByComponent from "../../components/SelectOrderBy";
import StatusSubscription from "./StatusSubscription";
import LoadingSkeletonTable from "@/components/Animations/Loading/SkeletonTable";
import MoreOptions from "../../components/MoreOptions";

import payService from "../services/payService";
import { downloadFile } from "@/assets/utils/files";

export default {
  name: "PlanHistoryComponent",
  data: () => ({
    apiResponse: {
      success: false
    },
    searchQuery: null,
    orderByQuery: null,
    perPaginate: 10,
    paginate: ["resultQuerySubscriptions"],
    subscriptions: [],
    options: [
      {
        key: "download",
        label: "Descargar factura"
      }
    ]
  }),
  computed: {
    resultQuerySubscriptions() {
      let subscriptions = this.subscriptions;
      return subscriptions
        .concat()
        .sort((a, b) => {
          switch (this.orderByQuery) {
            case "recent":
              return a.id < b.id ? 1 : -1;
            case "old":
              return a.id > b.id ? 1 : -1;
            case "a-z":
              return a.description.toLowerCase() < b.description.toLowerCase()
                ? -1
                : 1;
            case "z-a":
              return a.description.toLowerCase() > b.description.toLowerCase()
                ? -1
                : 1;
          }
        })
        .filter(item =>
          this.searchQuery
            ? item.description
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase())
            : item
        );
    }
  },
  methods: {
    toggleApiResponse(property, value) {
      this.apiResponse[property] = value;
    },
    onChangeSearch({ search }) {
      this.searchQuery = search;
    },
    onSelectOrderBy({ orderBy }) {
      this.orderByQuery = orderBy;
    },
    optionSelected({ option }, subscription) {
      switch (option) {
        case "download":
          this.downloadInvoiceSubscription(subscription);
          break;
      }
    },
    async getSubscriptions() {
      try {
        let response = await payService.getSubscriptions();
        const { success, data } = response.data;
        if (success) {
          this.subscriptions = data;
          this.toggleApiResponse("success", true);
        }
      } catch (error) {
        return false;
      }
    },
    getDesctiptionPlan(slug) {
      let cadena = [];
      if (slug) {
        cadena = slug?.split("&");
      }
      let title =
        this.$store.getters.getPlans.find(p => p.slug === cadena[0])
          ?.description || slug;

      return cadena.length > 1 ? `${title} Con Firma Electrónica` : title;
    },
    async downloadInvoiceSubscription({
      id: subscriptionId,
      description,
      has_payments
    }) {
      if (!has_payments) {
        this.$notifications.warning({
          message: "Factura no disponible"
        });
        return false;
      }
      try {
        this.$store.dispatch("toggleRequestLoading");
        let response = await payService
          .downloadInvoiceSubscription(subscriptionId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { data } = response;
        if (data) {
          downloadFile(data, `${description}`);
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    TopBarTitleComponent,
    InputSearchComponent,
    SelectOrderByComponent,
    LoadingSkeletonTable,
    StatusSubscription,
    MoreOptions
  },
  mounted() {
    this.getSubscriptions();
  }
};
</script>

<style lang="scss" scoped></style>
