<template>
  <el-dropdown @command="dropdownMenuSelected">
    <img :src="icoOptionGray" alt="options" width="24" class="float-right" />
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        :command="option.key"
        v-for="(option, index) in options"
        :key="index"
        >{{ option.label }}</el-dropdown-item
      >
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { icoOptionGray } from "@/services/resources";

export default {
  name: "MoreOptions",
  data() {
    return {
      icoOptionGray
    };
  },
  methods: {
    dropdownMenuSelected(menuItem) {
      this.$emit("optionSelected", {
        option: menuItem
      });
    }
  },
  props: {
    options: {
      default: () => [],
      type: Array
    }
  },
  components: {}
};
</script>

<style lang="scss" scoped></style>
