<template>
  <span
    :class="active ? 'text-color-success' : 'text-color-danger'"
    v-if="subscription"
    >{{ status }}</span
  >
</template>

<script>
export default {
  name: "StatusSubscription",
  data: () => ({}),
  computed: {
    active() {
      return this.subscription.active;
    },
    status() {
      return this.active ? "Activa" : "Vencida";
    }
  },
  methods: {},
  props: {
    subscription: {
      default: null
    }
  }
};
</script>

<style lang="scss" scoped></style>
